import * as React from "react";
import { Helmet } from "react-helmet";
import styled, { createGlobalStyle } from "styled-components";
import Box from "../components/Box";
import Container from "../components/Container";
import Link from "./Link";

const GlobalStyle = createGlobalStyle`
  * {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
  }

  body {
    font-family: Arial, Helvetica, sans-serif;
  }
`;

const NavLinks = styled.ul`
  display: flex;
  list-style: none;
  flex: 2;
  justify-content: flex-end;

  a {
    color: black;
    text-decoration: none;
    margin-left: 1rem;
  }

  a:visited {
    text-decoration: none;
    color: black;
  }
`;

const Layout = ({ children, title, pageDesc }) => {
  return (
    <React.Fragment>
      <Helmet defer={false}>
        <meta charSet="utf-8" />
        <title>{title}</title>
        <meta name="description" content={pageDesc} />
      </Helmet>
      <GlobalStyle />
      <Box as="main" display="flex" flexDirection="column" height="100vh">
        <section>
          <Container
            paddingTop="1rem"
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Box flex="1">
              <Link href="/">
                <h1 style={{ fontSize: "1rem" }}>Bitsolar Labs</h1>
              </Link>
            </Box>
            <NavLinks>
              <li>
                <a href="/simple-faq">Simple FAQ</a>
              </li>
              <li>
                <a href="/contact">Contact</a>
              </li>
            </NavLinks>
          </Container>
        </section>
        {children}
        <Box as="footer" paddingBottom="1rem">
          <Container>
            <Box as="p" fontSize="0.75rem">
              &copy; 2021 Bitsolar Labs LLC
            </Box>
            <Box as="p" fontSize="0.75rem">
              <Link href="/privacy">Privacy Policy</Link>
            </Box>
          </Container>
        </Box>
      </Box>
    </React.Fragment>
  );
};

export default Layout;
