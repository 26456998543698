import styled from "styled-components";
import Box from "./Box";

const Container = styled(Box)`
  max-width: 800px;
  margin: 0 auto;
  width: 100%;
  padding: 1rem;
`;

export default Container;
