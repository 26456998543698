import styled from "styled-components";

const Link = styled.a`
  text-decoration: none;
  color: #000000;

  &:visited {
    color: #000000;
  }
`;

export default Link;
