import styled from "styled-components";
import {
  space,
  color,
  layout,
  flexbox,
  textAlign,
  size,
  fontSize,
  lineHeight,
} from "styled-system";

const Box = styled.div(
  {
    boxSizing: "border-box",
    minWidth: 0,
  },
  space,
  color,
  layout,
  flexbox,
  textAlign,
  size,
  fontSize,
  lineHeight
);

export default Box;
